@import url("https://fonts.googleapis.com/css2?family=Karla&family=Montserrat:wght@600;700&display=swap");
* {
  box-sizing: border-box;
}

html {
  background-color: #f4f2f8;
  overflow: overlay;
}

body {
  margin: 0;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
