.header {
  align-items: center;
  background: rgb(46, 17, 127);
  background: -moz-linear-gradient(
    90deg,
    rgba(46, 17, 127, 1) 0%,
    rgba(83, 21, 188, 1) 43%,
    rgba(155, 87, 236, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(46, 17, 127, 1) 0%,
    rgba(83, 21, 188, 1) 43%,
    rgba(155, 87, 236, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(46, 17, 127, 1) 0%,
    rgba(83, 21, 188, 1) 43%,
    rgba(155, 87, 236, 1) 100%
  );
  display: flex;
  height: 4.5rem;
  justify-content: space-between;
  padding: 0 9rem;
  width: 100%;
}

.logo img {
  max-width: 240px;
  object-fit: cover;
  margin-left: -3rem;
}

@media (max-width: 1280px) and (min-width: 744px) {
  .logo img {
    margin-left: -6rem;
  }
}

@media (max-width: 744px) and (min-width: 300px) {
  .logo img {
    margin-left: -2rem;
  }
}
